<div class="main">
    <div class="logo">
        <img src="../../assets/images/logo-box-verde.png" alt="logo" style="max-width: 400px;">
    </div>
    <div class="title">
        <h1>BEM VINDO AO GERADOR DE JOGOS</h1>
    </div>
    <div class="jogos">
        <p>CLIQUE NO JOGO QUE QUER FAZER</p>
        <a href="gerador/lotofacil" class="lotoFacilButton">LOTOFÁCIL</a>
        <a href="gerador/megasena" class="megaSenaButton">MEGA-SENA</a>
        <a href="gerador/quina" class="quinaButton">QUINA</a>
        <a href="gerador/maismilionaria" class="milionariaButton">+MILIONÁRIA</a>
        <a href="gerador/lotomania" class="lotomaniaButton">LOTOMANIA</a>
    </div>
</div>