<div class="main">
    <div class="topBar quina">
        <div class="backButton">
            <a href="gerador"><i class="fa-solid fa-chevron-left"></i></a>
        </div>
        <div class="logo">
            <img src="./assets/images/logo-branco.png" alt="logoalt">
        </div>
        <div class="questionsButton">
            
        </div>
    </div>
    <div class="nomeJogo">
        <h1>Crie de forma automática jogos para</h1>
        <img src="./assets/images/quina-logo.png" alt="quina-logo">
    </div>
    <div class="container" id="container">
        <div class="criarJogo" id="criarJogo">
            <span class="errorMessage not-show" id="errorMessage">Você só pode gerar de 3 a 12 jogos por vez!</span>
            <h2>Quantos jogos você deseja fazer?</h2>
            <input type="number" class="quinaText" id="numJogos" min="3" max="12">
            <span>Você só pode gerar de <span class="coloredText quinaText">3 a 12 jogos</span> por vez</span>
            <button class="quina" id="gerarJogos">GERAR JOGOS</button>
        </div>
    </div>
</div>