import { Renderer2, Component, OnInit, Inject } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-quina',
  templateUrl: './quina.component.html',
  styleUrls: ['./quina.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class GeradorQuinaComponent implements OnInit {

  constructor(private _renderer2: Renderer2, @Inject(DOCUMENT) private _document: Document) { }

  ngOnInit(): void {
    let script = this._renderer2.createElement('script');
    script.type = `text/javascript`;
    script.text = `
    let num_jogos;

    function gerarApostas(numApostas) {
        let qtdNumsApostados = 5;
        let numerosPermitidos = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80];
        let apostasGeradas = [];
        for (let x = 0; x < numApostas; x++) {
            let apostaGerada = [];
            while (apostaGerada.length < qtdNumsApostados) {
                let valid = false;
                while(valid === false) {
                    while(apostaGerada.length < qtdNumsApostados) {
                        var random = Math.floor(Math.random() * numerosPermitidos.length);
                        if(!apostaGerada.includes(numerosPermitidos[random])) {
                            apostaGerada.push(numerosPermitidos[random]);
                        }
                    }
                    apostaGerada.sort(function(a, b) { return a - b; });
                    if(!apostasGeradas.includes(apostaGerada)) {
                        valid = true;
                        apostasGeradas.push(apostaGerada);
                    }
                }
            }
        }
        return apostasGeradas;
    }
    
    $(document).ready(function() {
        $(document).on("click", "#gerarJogos", function() {
            $("#errorMessage").addClass("not-show");
            if($("#numJogos").val() < 3 || $("#numJogos").val() > 12) {
                $("#errorMessage").removeClass("not-show");
                return false;
            }
            $(this).css("background-color", "#d3d3d3");
            num_jogos = $("#numJogos").val();
            let apostasDivs = \`
                <div class="jogosCriados" id="jogosCriados">
                    <h2>SEUS \${num_jogos} JOGOS FORAM CRIADOS</h2>
                    <button class="quina" id="novosJogos">GERAR NOVOS JOGOS</button>
            \`;
            let apostas = gerarApostas(num_jogos);
            setTimeout(() => {
                for(let x = 0; x < apostas.length; x++) {
                    let apostaDiv = \`<div class="divJogo jogoQuina">\`;
                    for(let y = 0; y < apostas[x].length; y++) {
                        let numApostaDiv = \`\r<div class="numJogo numQuina">\${apostas[x][y]}</div>\`;
                        apostaDiv = apostaDiv + numApostaDiv;
                    }
                    apostaDiv = apostaDiv + "</div>";
                    apostasDivs = apostasDivs + apostaDiv;
                }
                apostasDivs = apostasDivs + '</div>';
                $(this).closest("#container").append(apostasDivs)}, 2000);
            setTimeout(() => {$(this).closest("#criarJogo").remove()}, 2000);
        });
    });
    
    $(document).ready(function() {
        $(document).on("click", "#novosJogos", function() {
            $(this).closest("#container").append(\`
                <div class="criarJogo" id="criarJogo">
                    <h2>Quantos jogos você deseja fazer?</h2>
                    <input type="number" class="quinaText" id="numJogos">
                    <span>Você só pode gerar de <span class="coloredText quinaText">3 a 12 jogos</span> por vez</span>
                    <button class="quina" id="gerarJogos">GERAR JOGOS</button>
                </div>
            \`);
            $(this).closest("#jogosCriados").remove();
        });
    });
    `;

    this._renderer2.appendChild(this._document.body, script);
  }

}
