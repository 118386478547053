import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-maismilionaria',
  templateUrl: './maismilionaria.component.html',
  styleUrls: ['./maismilionaria.component.css']
})
export class MaismilionariaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
