import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private url: string = `${environment.apiUrl}/auth`;

  constructor(private http: HttpClient) {}

  check() {
    return this.http.get(`${this.url}`).toPromise();
  }

  login(email: string) {
    return this.http.post(`${this.url}`, {
      email: email
    });
  }
}
