<div id="main-wrapper" [ngClass]="{ 'show-sidebar': showMobileMenu }" dir="ltr" data-theme="light"
  data-layout="vertical" [attr.data-sidebartype]="sidebartype" data-sidebar-position="absolute"
  data-header-position="fixed" data-boxed-layout="full">

  <header class="topbar" [attr.data-navbarbg]="dataLogoBg">
    <nav class="d-flex top-navbar navbar-expand-md navbar-light">
      <div class="navbar-header" [ngClass]="expandLogo ? 'expand-logo' : ''" [attr.data-logobg]="dataLogoBg" style="display: flex;">
        <a (click)="showMobileMenu = !showMobileMenu" class="nav-toggler waves-effect waves-light d-block d-md-none"
          href="javascript:void(0)" style="flex: 1; text-align: left;">
          <img src="assets/images/menu-items/icon-menu.png" [ngClass]="showMobileMenu" style="width: 30px;"/>
        </a>

        <a class="navbar-brand" href="{{home_url}}" style="flex: 2; text-align: center;">
          <span class="logo-text">
            <img src="assets/images/logo-preto.png" class="dark-logo" alt="homepage" style="width: 80%;"/>
          </span>
        </a>
        <a class="topbartoggler d-block d-md-none waves-effect waves-light" href="#"
          data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
          aria-expanded="false" aria-label="Toggle navigation" style="flex: 1; text-align: right;">
        </a>
      </div>
      <div class="navbar-collapse collapse" id="navbarSupportedContent" [attr.data-navbarbg]="dataLogoBg">
        <app-navigation (toggleSidebar)="toggleSidebarType()" class="w-100"></app-navigation>
      </div>
    </nav>
  </header>

  <aside class="left-sidebar" [attr.data-sidebarbg]="dataLogoBg" (mouseover)="Logo()" (mouseout)="Logo()">
    <div class="scroll-sidebar" [perfectScrollbar]="config">
      <app-sidebar (toggleSidebar)="showMobileMenu = false"></app-sidebar>
    </div>
  </aside>
  <!-- ============================================================== -->
  <!-- Start Page Content -->
  <!-- ============================================================== -->
  <router-outlet></router-outlet>
  <!-- ============================================================== -->
  <!-- End Start Page Content -->
  <!-- ============================================================== -->