<!-- ============================================================== -->
<!-- toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-left mr-auto">
  <li class="nav-item d-none d-md-block d-lg-none">
    <a (click)="toggleSidebar.emit()" class="nav-link sidebartoggler waves-effect waves-light"
      href="javascript:void(0)">
      <i class="mdi mdi-menu font-24"></i>
    </a>
  </li>
</ul>
<!-- ============================================================== -->
<!-- Right side toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav d-flex flex-row-reverse">
  <!-- <li class="nav-item m-r-20">
    <a href="https://www.google.com/" class="btn btn-rounded btn-danger m-t-15">Contratar</a>
  </li> -->
  <!-- ============================================================== -->
  <!-- User profile and search -->
  <!-- ============================================================== -->
  <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
    <a ngbDropdownToggle class="nav-link dropdown-toggle text-muted waves-effect waves-dark pro-pic"
      href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    </a>
  </li>
  <!-- ============================================================== -->
  <!-- User profile and search -->
  <!-- ============================================================== -->
</ul>
