import { Component, Input, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router, NavigationEnd, ActivatedRoute, Data } from "@angular/router";
import { filter, map, mergeMap } from "rxjs/operators";
import { Observer } from "rxjs";

@Component({
  selector: "app-breadcrumb",
  templateUrl: "./breadcrumb.component.html",
})
export class BreadcrumbComponent implements OnInit {
  pageInfo: Data = Object.create(null);
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .pipe(filter((route) => route.outlet === "primary"))
      .pipe(mergeMap((route) => route.data))
      .subscribe((event: Data) => {
        let title = event["title"];
        event.urls?.forEach((url: any) => {
          if (url.title) title += ` > ${url.title}`;
        });
        event["title"] = title;
        this.titleService.setTitle(title);
        this.pageInfo = event;
      });
  }
  ngOnInit() {}
}
