import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { FullComponent } from "./layouts/full/full.component";
import { GeradorComponent } from "./gerador/gerador.component";
import { GeradorLotofacilComponent } from "./gerador/lotofacil/lotofacil.component";
import { GeradorMegasenaComponent } from "./gerador/megasena/megasena.component";
import { GeradorLotomaniaComponent } from "./gerador/lotomania/lotomania.component";
import { GeradorQuinaComponent } from "./gerador/quina/quina.component";
import { GeradorMaismilionariaComponent } from "./gerador/maismilionaria/maismilionaria.component";
import { GeradoresLayoutComponent } from "./layouts/geradores/geradores.component";

export const Approutes: Routes = [
  { path: "", redirectTo: "/home", pathMatch: "full" },
  {
    path: "register",
    loadChildren: () =>
      import("./register/register.module").then((m) => m.RegisterModule),
  },
  {
    path: "",
    component: FullComponent,
    children: [
      {
        path: "home",
        loadChildren: () =>
          import("./home/home.module").then((m) => m.HomeModule),
      },
      {
        path: "home-expired",
        loadChildren: () =>
          import("./home-expired/home-expired.module").then((m) => m.HomeExpiredModule),
      },
      {
        path: "lotofacil",
        loadChildren: () =>
          import("./lotofacil/lotofacil.module").then((m) => m.LotofacilModule),
      },
      {
        path: "megasena",
        loadChildren: () =>
          import("./megasena/megasena.module").then((m) => m.MegasenaModule),
      },
      {
        path: "lotomania",
        loadChildren: () =>
          import("./lotomania/lotomania.module").then((m) => m.LotomaniaModule),
      },
      {
        path: "quina",
        loadChildren: () =>
          import("./quina/quina.module").then((m) => m.QuinaModule),
      },
      {
        path: "lotopolio",
        loadChildren: () =>
          import("./lotopolio/lotopolio.module").then((m) => m.LotopolioModule),
      },
      {
        path: "notification",
        loadChildren: () =>
          import("./notification/notification.module").then((m) => m.NotificationModule),
      },
      {
        path: "grupo",
        loadChildren: () =>
          import("./group/group.module").then((m) => m.GroupModule),
      },
      {
        path: "404",
        loadChildren: () =>
          import("./404/404.module").then((m) => m.NotFoundModule),
      },
      {
        path: "selling",
        loadChildren: () =>
          import('./selling-page/selling-page.module').then((m) => m.SellingPageModule),
      },
      {
        path: "expired",
        loadChildren: () =>
          import('./expired-trial/expiring-trial.module').then((m) => m.ExpiredTrialModule)
      },
      {
        path: "zapdaloto",
        loadChildren: () =>
          import('./messages-screen/messages-screen.module').then((m) => m.MessageScreenModule)
      },
    ],
  },
  {
    path: "gerador",
    component: GeradoresLayoutComponent,
    children: [
      {
        path: "",
        component: GeradorComponent
      },
      {
        path: "lotofacil",
        component: GeradorLotofacilComponent
      },
      {
        path: "megasena",
        component: GeradorMegasenaComponent
      },
      {
        path: "lotomania",
        component: GeradorLotomaniaComponent
      },
      {
        path: "quina",
        component: GeradorQuinaComponent
      },
      {
        path: "maismilionaria",
        component: GeradorMaismilionariaComponent
      },
    ]
  },
  {
    path: "**",
    redirectTo: "/404",
  },
];
