<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div id="main-wrapper" [ngClass]="{ 'show-sidebar': showMobileMenu }" dir="ltr" data-theme="light"
  data-layout="vertical" [attr.data-sidebartype]="sidebartype" data-sidebar-position="absolute"
  data-header-position="fixed" data-boxed-layout="full">
  <app-loading></app-loading>
  <!-- ============================================================== -->
  <!-- Topbar header - style you can find in topbar.scss -->
  <!-- ============================================================== -->
  <header class="topbar" [attr.data-navbarbg]="dataLogoBg">
    <nav class="d-flex top-navbar navbar-expand-md navbar-light">
      <div class="navbar-header" [ngClass]="expandLogo ? 'expand-logo' : ''" [attr.data-logobg]="dataLogoBg" style="display: flex;">
        <!-- This is for the sidebar toggle which is visible on mobile only -->
        <a (click)="showMobileMenu = !showMobileMenu" class="nav-toggler waves-effect waves-light d-block d-md-none"
          href="javascript:void(0)" style="flex: 1; text-align: left;">
          <img src="assets/images/menu-items/icon-menu.png" [ngClass]="showMobileMenu" style="width: 30px;"/>
        </a>
        <!-- ============================================================== -->
        <!-- Logo -->
        <!-- ============================================================== -->
        <a class="navbar-brand" href="{{home_url}}" style="flex: 2; text-align: center;">
          <span class="logo-text">
            <img src="assets/images/logo-preto.png" class="dark-logo" alt="homepage" style="width: 80%;"/>
          </span>
        </a>
        <a class="topbartoggler d-block d-md-none waves-effect waves-light" href="#"
          data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
          aria-expanded="false" aria-label="Toggle navigation" style="flex: 1; text-align: right;">
        </a>
      </div>
      <div class="navbar-collapse collapse" id="navbarSupportedContent" [attr.data-navbarbg]="dataLogoBg">
        <app-navigation (toggleSidebar)="toggleSidebarType()" class="w-100"></app-navigation>
      </div>
    </nav>
  </header>
  <!-- ============================================================== -->
  <!-- Left Sidebar - style you can find in sidebar.scss  -->
  <!-- ============================================================== -->
  <aside class="left-sidebar" [attr.data-sidebarbg]="dataLogoBg" (mouseover)="Logo()" (mouseout)="Logo()">
    <!-- Sidebar scroll-->
    <div class="scroll-sidebar" [perfectScrollbar]="config">
      <app-sidebar (toggleSidebar)="showMobileMenu = false"></app-sidebar>
    </div>
    <!-- End Sidebar scroll-->
  </aside>
  <!-- ============================================================== -->
  <!-- End Left Sidebar - style you can find in sidebar.scss  -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
  <!-- Page wrapper  -->
  <!-- ============================================================== -->
  <div class="page-wrapper">
    <!-- ============================================================== -->
    <!-- Container fluid  -->
    <!-- ============================================================== -->
    <!-- <app-breadcrumb *ngIf="show_breadcrumb"></app-breadcrumb> -->
    <div [ngClass]="{'container-fluid': isContainerFluid === true}" id="router-wrapper">
      <!-- ============================================================== -->
      <!-- Start Page Content -->
      <!-- ============================================================== -->
      <router-outlet></router-outlet>
      <!-- ============================================================== -->
      <!-- End Start Page Content -->
      <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Container fluid  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- footer -->
    <!-- ============================================================== -->
    <footer class="footer text-center" *ngIf="show_footer">
      All Rights Reserved by Zap da Sorte.
    </footer>
    <!-- ============================================================== -->
    <!-- End footer -->
    <!-- ============================================================== -->
  </div>
  <!-- ============================================================== -->
  <!-- End Page wrapper  -->
  <!-- ============================================================== -->
</div>
