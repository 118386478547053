import {
  Component,
  AfterViewInit,
  OnInit,
  EventEmitter,
  Output,
  VERSION, 
  ViewChild, 
  ElementRef,
  HostListener,
  Inject
} from "@angular/core";
import { ROUTES } from "./menu-items";
import { RouteInfo } from "./sidebar.metadata";
import { Router, ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DOCUMENT } from '@angular/common'; 
import { AuthService } from "../../auth/auth.service";
//declare var $: any;

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  @Output()
  toggleSidebar = new EventEmitter<void>();
  showMenu = "";
  showSubMenu = "";
  deferredPrompt!: any;
  public actualUrl = "/home";
  public sidebarnavItems: RouteInfo[] = [];
  public home_url: string = "/home";
  public access_level!: any;
  public access_class: string;
  // this is for the open close
  addExpandClass(element: string) {
    if (element === this.showMenu) {
      this.showMenu = "0";
    } else {
      this.showMenu = element;
    }
    this.toggleSidebar.emit();
  }

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    public service: AuthService
  ) { 
    this.access_level = localStorage.getItem("zapDaLotoAccessLevel");
    if(this.access_level === "1") {
      this.access_class = "";
    } else {
      this.access_class = "-ativado";
    }
  }

  // End open close
  ngOnInit() {
    this.actualUrl = this.router.url;
    this.sidebarnavItems = ROUTES.filter((sidebarnavItem) => sidebarnavItem);
    this.handle_home();
  }

  async handle_home(): Promise<void> {
    this.home_url = "/home";
    return;
  }
}
