import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';

import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
//declare var $: any;

@Component({
  selector: 'app-geradores-layout',
  templateUrl: './geradores.component.html',
  styleUrls: ['./geradores.component.scss']
})
export class GeradoresLayoutComponent implements OnInit {

	public config: PerfectScrollbarConfigInterface = {};

  constructor(public router: Router) {}

	public innerWidth: number=0;
	public defaultSidebar: string='';
	public showMobileMenu = false;
	public expandLogo = false;
	public sidebartype = 'full';
  public home_url: string = "/home";
  public show_breadcrumb: boolean = true;
  public isContainerFluid: boolean = true;
  public dataLogoBg: string = "skin1";
  public show_footer: boolean = true;
  Logo() {
    this.expandLogo = !this.expandLogo;
  }

  ngOnInit() {
    if (this.router.url === '/') {
      this.router.navigate(['/home']);
    }
    this.defaultSidebar = this.sidebartype;
    this.handleSidebar();
    this.handle_home();
  }

  setShowBreadcrumb(state: boolean) {
    this.show_breadcrumb = state;
  }

  setIsContainerFluid(state: boolean) {
    this.isContainerFluid = state;
  }

  setDataLogoBg(dataLogo: string) {
    this.dataLogoBg = dataLogo;
  }

  setShowFooter(state: boolean) {
    this.show_footer = state;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.handleSidebar();
  }

  handleSidebar() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 1170) {
      this.sidebartype = 'mini-sidebar';
    } else {
      this.sidebartype = this.defaultSidebar;
    }
  }

  toggleSidebarType() {
    switch (this.sidebartype) {
      case 'full':
        this.sidebartype = 'mini-sidebar';
        break;

      case 'mini-sidebar':
        this.sidebartype = 'full';
        break;

      default:
    }
  }

  async handle_home(): Promise<void> {
    const email = localStorage.getItem("zapDaLotoUser") || "";
    var home_new_url: string = "";
    var check: any = [];
    this.home_url = "/";
    return;
  }

}
