<!-- <nav class="sidebar-nav">
    <ul id="sidebarnav">
        <li class="sidebar-item" [class.active]="showMenu === sidebarnavItem.title"
            *ngFor="let sidebarnavItem of sidebarnavItems"
            [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'">
            <div class="nav-small-cap" *ngIf="sidebarnavItem.extralink === true"><i [ngClass]="[sidebarnavItem.icon]" style="font-size: 14px; margin-right: 13px;
                margin-left: 13px;"></i><span style="font-size: 14px;">{{sidebarnavItem.title}}</span></div>
            <ng-container *ngIf="!sidebarnavItem.disabled">
                <a class="sidebar-link waves-effect waves-dark"
                    [routerLink]="sidebarnavItem.class === '' ? [sidebarnavItem.path] : null" [ngClass]="[sidebarnavItem.class]"
                    *ngIf="!sidebarnavItem.extralink;" (click)="addExpandClass(sidebarnavItem.title)"
                    [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'">
                    <i [ngClass]="[sidebarnavItem.icon]" style="font-size: 14px;"></i>
                    <span class="hide-menu" style="text-transform: uppercase;">{{sidebarnavItem.title}}</span>
                </a>
            </ng-container>
            <ng-container *ngIf="sidebarnavItem.disabled">
                <span class="sidebar-link waves-effect waves-dark">
                    <i [ngClass]="[sidebarnavItem.icon]" style="font-size: 14px;"></i>
                    <span class="hide-menu" style="text-transform: uppercase;">{{sidebarnavItem.title}}</span>
                </span>
            </ng-container>
        </li>
    </ul>
</nav> -->

<style>
    * {
      font-family: 'Open Sans', sans-serif !important;
    }
    a {
      margin: 0 !important; 
      color: #ffffff; 
      font-size: 16px; 
      font: 500 20px/32px Roboto,'Helvetica Neue', sans-serif;
    }
    .main {
        margin: 10px 10px;
    }
    .menu-home-item img {
        width: 50px;
        height: 50px;
        border-radius: 50%; 
        background-color: white; 
        padding: 10px 10px;
        cursor: pointer;
    }
    .menu-home-item:last-child img {
        width: 50px;
        height: 50px;
        border-radius: 50%; 
        background-color: white; 
        padding: 2px 2px;
        cursor: pointer;
    }
    .menu-home-item a {
      text-decoration: none;
    }
    .menu-home-item h3 {
        font-size: 13px;
        color: #ffffff;
        margin-bottom: 0px !important;
        font-weight: bold;
        line-height: normal;
    }
    .title-div-game {
        padding: 10px 10px;
    }
    .title-div-game a {
        margin-bottom: 0px !important;
        font-size: 26px;
        font-weight: bold;
        color: #ffffff;
        letter-spacing: 1px;
    }
    .lotofacil-options,
    .megasena-options,
    .lotomania-options,
    .quina-options,
    .lotopolio-options {
        padding: 10px 10px;
        margin-bottom: 10px;
        background-color: #ffffff;
        border-radius: 10px;
    }
    .lotofacil-options {
        border: 1px solid #c20cb5;
    }
    .lotopolio-options {
        border: 1px solid #e20909;
    }
    .megasena-options {
        border: 1px solid #209869;
    }
    .lotomania-options {
        border: 1px solid #e57200;
    }
    .quina-options {
        border: 1px solid #0967e2;
    }
    .options-grid-r {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 10px;
        text-align: center;
    }
    .options-grid-f {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        gap: 10px;
        text-align: center;
    }
    .options-grid-fixos {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        gap: 10px;
        text-align: center;
    }
    .option-select-lotofacil,
    .option-select-megasena,
    .option-select-lotomania,
    .option-select-quina,
    .option-select-lotopolio,
    .option-select-lotofacil-ativado,
    .option-select-megasena-ativado,
    .option-select-lotomania-ativado,
    .option-select-quina-ativado,
    .option-select-lotopolio-ativado {
        margin: 0 !important;
        border-radius: 50px;
        cursor: pointer;
    }
    .option-select-lotofacil-ativado {
      background-color: #c20cb5;
    }
    .option-select-megasena-ativado {
      background-color: #209869;
    }
    .option-select-lotomania-ativado {
      background-color: #e57200;
    }
    .option-select-quina-ativado {
      background-color: #0967e2;
    }
    .option-select-lotopolio-ativado {
      background-color: #e20909;
    }
    .option-select-lotofacil {
      background-color: #e7cfe5;
    }
    .option-select-megasena {
      background-color: #d4eae1;
    }
    .option-select-lotomania {
      background-color: #edd8c3;
    }
    .option-select-quina {
      background-color: #c7d4e4;
    }

    .option-select-lotofacil a,
    .option-select-megasena a,
    .option-select-lotomania a,
    .option-select-quina a {
        margin: 0 !important;
        color: rgb(119, 119, 119);
        font-size: 16px;
        text-decoration: none;
    }
    .option-select-lotofacil-ativado a,
    .option-select-megasena-ativado a,
    .option-select-lotomania-ativado a,
    .option-select-quina-ativado a,
    .option-select-lotopolio-ativado a{
        margin: 0 !important;
        color: #ffffff;
        font-size: 16px;
        text-decoration: none;
    }
    .option-select-lotofacil:hover, 
    .option-select-lotofacil:active {
      box-shadow: 0 0 10px #b05fa8!important;
    }
    .option-select-megasena:hover, 
    .option-select-megasena:active {
      box-shadow: 0 0 10px #45a97f!important;
    }
    .option-select-lotomania:hover, 
    .option-select-lotomania:active {
      box-shadow: 0 0 10px #a8815b!important;
    }
    .option-select-quina:hover, 
    .option-select-quina:active {
      box-shadow: 0 0 10px #6386b1!important;
    }

    .option-select-lotofacil-ativado:hover, 
    .option-select-lotofacil-ativado:active {
      box-shadow: 0 0 10px #780070;
    }
    .option-select-megasena-ativado:hover, 
    .option-select-megasena-ativado:active {
      box-shadow: 0 0 10px #007d4d;
    }
    .option-select-lotomania-ativado:hover, 
    .option-select-lotomania-ativado:active {
      box-shadow: 0 0 10px #c56300;
    }
    .option-select-quina-ativado:hover, 
    .option-select-quina-ativado:active {
      box-shadow: 0 0 10px #0854b9;
    }
    
    .title-game h3 {
        margin-bottom: 5px !important;
        font-size: 14px;
        font-weight: 500;
        color: #c20cb5;
    }
    .footer {
      margin-top: 30px;
      padding: 0 !important;
    }
    .versaofull {
      width: 100%;
      border-radius: 10px;
      cursor: pointer;
      background-color: #e57200;
      padding: 10px 10px;
      text-align: center;
      justify-content: center;
    }
    .versaofull a {
      font-size: 14px;
      margin: 0 !important;
      font-weight: bold;
      color: #ffffff;
      text-decoration: none;
    }
    @media screen and (max-width: 768px) {
      .menu-home-item img {
        width: 70px;
        height: 70px;
      }
      .menu-home-item:last-child img {
        width: 70px;
        height: 70px;
      }
      .menu-home-item h3 {
        font-size: 16px;
      }
      .versaofull a {
        font-size: 18px;
      }
    }
</style>

<mat-list>
</mat-list>
<div class="main">
  <div class="topbar-menu">
    <div class="menu-home" style="display: flex; min-width: 60px; text-align: center; margin-bottom: 5px; margin-top: 12px;">
      <div class="menu-home-item" style="flex: 1;">
        <a href="{{home_url}}">
          <img src="../../assets/images/menu-items/icon-inicio.png" alt="Inicio">
          <h3>Início</h3>
        </a>
      </div>
      <div class="menu-home-item" style="flex: 1;">
        <a href="/grupo">
          <img src="../../assets/images/menu-items/icon-grupo.png" alt="Grupo">
          <h3>Grupo de Palpites</h3>
        </a>
      </div>
      <div class="menu-home-item" style="flex: 1;">
        <a href="https://terrordasloterias.com/" target="_blank">
          <img src="../../assets/images/menu-items/icon-info.png" alt="More">
          <h3>Saiba Mais</h3>
        </a>
      </div>
      <div class="menu-home-item" style="flex: 1;">
        <a href="/zapdaloto">
          <img src="../../assets/images/menu-items/icon-zap-da-loto.png" alt="Zap da Sorte">
          <h3>Zap da Sorte</h3>
        </a>
      </div>
    </div>
  </div>
  <div id="banner-wrapper" class="game" style="width: 100%; margin: 40px 0 20px 0; min-height: 180px;">
    <a href="/zapdaloto">
      <img src="../../assets/images/banner.png" style="max-width: 100%; max-height: 100%; border-radius: 8px;">
    </a>
  </div>
  <div id="banner-wrapper" class="game" style="width: 100%; margin: 40px 0 20px 0; min-height: 180px;">
    <a href="/gerador">
      <img src="../../assets/images/banner2.png" style="max-width: 100%; max-height: 100%; border-radius: 8px;">
    </a>
  </div>
  <div class="games">
    <div class="game" id="lotopolio">
      <div class="title-div-game">
        <a>Estratégia Política</a>
      </div>
      <div class="lotopolio-options">
        <div class="options-grid-f">
          <a href="/lotopolio/d17" style="text-decoration: none;">
            <div class="option-select-lotopolio-ativado">
              <a href="/lotopolio/d17">Lotopolio 14</a>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="game" id="lotofacil">
      <div class="title-div-game">
        <a>Estratégias Lotofácil</a>
      </div>
      <div class="lotofacil-options">
        <div class="options-grid-r">
          <a href="/lotofacil/d17" style="text-decoration: none;">
            <div class="option-select-lotofacil-ativado">
              <a href="/lotofacil/d17">17 por 8</a>
            </div>
          </a>
          <a href="/lotofacil/d18" style="text-decoration: none;">
            <div class="option-select-lotofacil-ativado">
              <a>18 Dezenas</a>
            </div>
          </a>
          <a href="/lotofacil/d19" style="text-decoration: none;">
            <div class="option-select-lotofacil-ativado">
              <a>19 Dezenas</a>
            </div>
          </a>
          <a href="/lotofacil/d20" style="text-decoration: none;">
            <div class="option-select-lotofacil-ativado">
              <a>20 Dezenas</a>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="game" id="megasena">
      <div class="title-div-game">
        <a>Estratégias Mega-Sena</a>
      </div>
      <div class="megasena-options">
        <div class="options-grid-fixos">
          <a href="/megasena/f10" style="text-decoration: none;">
            <div class="option-select-megasena-ativado">
              <a>Fechamento 10</a>
            </div>
          </a>
          <a href="/megasena/b25" style="text-decoration: none;">
            <div class="option-select-megasena-ativado">
              <a>Bolão 25</a>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="game" id="lotomania">
      <div class="title-div-game">
        <a>Estratégias Lotomania</a>
      </div>
      <div class="lotomania-options">
        <div class="options-grid-fixos">
          <a href="/lotomania/e20" style="text-decoration: none;">
            <div class="option-select-lotomania-ativado">
              <a>Erre 20</a>
            </div>
          </a>
          <a href="/lotomania/f60" style="text-decoration: none;">
            <div class="option-select-lotomania-ativado">
              <a>Fechamento 60</a>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="game" id="quina">
      <div class="title-div-game">
        <a>Estratégias Quina</a>
      </div>
      <div class="quina-options">
        <div class="options-grid-fixos">
          <a href="/quina/d12" style="text-decoration: none;">
            <div class="option-select-quina-ativado">
              <a>Desdobra 12</a>
            </div>
          </a>
          <a href="/quina/d15" style="text-decoration: none;">
            <div class="option-select-quina-ativado">
              <a>Desdobra 15</a>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <!-- <div class="text-center mt-2 mb-2" *ngIf="access_level == 1">
      <a class="btn btn-rounded btn-purchase-pulse text-light" style="width: 200px;" href="https://terrordasloterias.com/" target="_blank">DESBLOQUEAR VERSÃO COMPLETA</a>
    </div> -->
  </div>
</div>