<div class="main" id="loading-screen">
    <div class="container">
        <div class="logo">
            <img src="assets/images/logo-preto.png" alt="loading-zapdaloto">
            <h1>Carregando...</h1>
        </div>
        <div class="footer">
            <p>Gerando as melhores combinações <br/>com inteligência artificial</p>
        </div>
    </div>
</div>